//@import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Inter:wght@400;500;600;700;800;900&display=fallback');
//@import url('src/assets/fonts/MinimaExpandedSSK.ttf');
//@import url('https://fonts.googleapis.com/css2?family=Encode+Sans+Expanded:wght@100;200;300;400;500;600;700;800;900&display=swap');

@mixin font($font-family, $font-file) {
  @font-face {
    font-family: $font-family;
    src: url($font-file + '.eot');
    src: url($font-file + '.eot?#iefix') format('embedded-opentype'),
  url($font-file + '.woff') format('woff'),
  url($font-file + '.ttf') format('truetype'),
  url($font-file + '.svg#' + $font-family) format('svg');
    font-weight: normal;
    font-style: normal;
  }
}

@include font('MinimaExpandedSSK', '../assets/fonts/MinimaExpandedSSK');
* {
  font-family: MinimaExpandedSSK;
}

@tailwind base;
@tailwind components;

// Additional styles
@import 'additional-styles/utility-patterns.scss';
@import 'additional-styles/range-slider.scss';
@import 'additional-styles/toggle-switch.scss';
@import 'additional-styles/theme.scss';

/*
@font-face {
  font-family: 'MinimaExpandedSSK';
  src: url('src/assets/fonts/MinimaExpandedSSK.ttf');
  font-weight: normal;
  font-style: normal;
}
*/

@tailwind utilities;

// Additional Tailwind directives: https://tailwindcss.com/docs/functions-and-directives/#responsive
@responsive {
  .rtl {
    direction: rtl;
  }
}

// See Alpine.js: https://github.com/alpinejs/alpine#x-cloak
[x-cloak] {
  display: none;
}

// AOS styles
$aos-distance: 16px;
@import 'node_modules/aos/src/sass/aos.scss';

.dark .dark\:bg-gray-900 {
  background-color: rgba(16, 14, 18, var(--tw-bg-opacity))
}

* {
  font-family: MinimaExpandedSSK;
}
